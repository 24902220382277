export default {
  cToken: {
    errors: {
      0: {
        name: 'NO_ERROR',
        description: 'Not a failure.',
      },
      1: {
        name: 'UNAUTHORIZED',
        description: 'The sender is not authorized to perform this action.',
      },
      2: {
        name: 'BAD_INPUT',
        description: 'An invalid argument was supplied by the caller.',
      },
      3: {
        name: 'COMPTROLLER_REJECTION',
        description: 'The action would violate the comptroller policy.',
      },
      4: {
        name: 'COMPTROLLER_CALCULATION_ERROR',
        description: 'An internal calculation has failed in the comptroller.',
      },
      5: {
        name: 'INTEREST_RATE_MODEL_ERROR',
        description: 'The interest rate model returned an invalid value.',
      },
      6: {
        name: 'INVALID_ACCOUNT_PAIR',
        description: 'The specified combination of accounts is invalid.',
      },
      7: {
        name: 'INVALID_CLOSE_AMOUNT_REQUESTED',
        description: 'The amount to liquidate is invalid.',
      },
      8: {
        name: 'INVALID_COLLATERAL_FACTOR',
        description: 'The collateral factor is invalid.',
      },
      9: {
        name: 'MATH_ERROR',
        description: 'A math calculation error occurred.',
      },
      10: {
        name: 'MARKET_NOT_FRESH',
        description: 'Interest has not been properly accrued.',
      },
      11: {
        name: 'MARKET_NOT_LISTED',
        description: 'The market is not currently listed by its comptroller.',
      },
      12: {
        name: 'TOKEN_INSUFFICIENT_ALLOWANCE',
        description:
          'ERC-20 contract must allow Money Market contract to call transferFrom. The current allowance is either 0 or less than the requested supply, repayBorrow or liquidate amount.',
      },
      13: {
        name: 'TOKEN_INSUFFICIENT_BALANCE',
        description:
          'Caller does not have sufficient balance in the ERC-20 contract to complete the desired action.',
      },
      14: {
        name: 'TOKEN_INSUFFICIENT_CASH',
        description:
          'The market does not have a sufficient cash balance to complete the transaction. You may attempt this transaction again later.',
      },
      15: {
        name: 'TOKEN_TRANSFER_IN_FAILED',
        description: 'Failure in ERC-20 when transfering token into the market.',
      },
      16: {
        name: 'TOKEN_TRANSFER_OUT_FAILED',
        description: 'Failure in ERC-20 when transfering token out of the market.',
      },
    },
    info: {
      0: {
        name: 'ACCEPT_ADMIN_PENDING_ADMIN_CHECK',
        description: '',
      },

      1: {
        name: 'ACCRUE_INTEREST_ACCUMULATED_INTEREST_CALCULATION_FAILED',
        description: '',
      },

      2: {
        name: 'ACCRUE_INTEREST_BORROW_RATE_CALCULATION_FAILED',
        description: '',
      },

      3: {
        name: 'ACCRUE_INTEREST_NEW_BORROW_INDEX_CALCULATION_FAILED',
        description: '',
      },

      4: {
        name: 'ACCRUE_INTEREST_NEW_TOTAL_BORROWS_CALCULATION_FAILED',
        description: '',
      },

      5: {
        name: 'ACCRUE_INTEREST_NEW_TOTAL_RESERVES_CALCULATION_FAILED',
        description: '',
      },

      6: {
        name: 'ACCRUE_INTEREST_SIMPLE_INTEREST_FACTOR_CALCULATION_FAILED',
        description: '',
      },

      7: {
        name: 'BORROW_ACCUMULATED_BALANCE_CALCULATION_FAILED',
        description: '',
      },

      8: {
        name: 'BORROW_ACCRUE_INTEREST_FAILED',
        description: '',
      },

      9: {
        name: 'BORROW_CASH_NOT_AVAILABLE',
        description: '',
      },

      10: {
        name: 'BORROW_FRESHNESS_CHECK',
        description: '',
      },

      11: {
        name: 'BORROW_NEW_TOTAL_BALANCE_CALCULATION_FAILED',
        description: '',
      },

      12: {
        name: 'BORROW_NEW_ACCOUNT_BORROW_BALANCE_CALCULATION_FAILED',
        description: '',
      },

      13: {
        name: 'BORROW_MARKET_NOT_LISTED',
        description: '',
      },

      14: {
        name: 'BORROW_COMPTROLLER_REJECTION',
        description: '',
      },

      15: {
        name: 'LIQUIDATE_ACCRUE_BORROW_INTEREST_FAILED',
        description: '',
      },

      16: {
        name: 'LIQUIDATE_ACCRUE_COLLATERAL_INTEREST_FAILED',
        description: '',
      },

      17: {
        name: 'LIQUIDATE_COLLATERAL_FRESHNESS_CHECK',
        description: '',
      },

      18: {
        name: 'LIQUIDATE_COMPTROLLER_REJECTION',
        description: '',
      },

      19: {
        name: 'LIQUIDATE_COMPTROLLER_CALCULATE_AMOUNT_SEIZE_FAILED',
        description: '',
      },

      20: {
        name: 'LIQUIDATE_CLOSE_AMOUNT_IS_UINT_MAX',
        description: '',
      },

      21: {
        name: 'LIQUIDATE_CLOSE_AMOUNT_IS_ZERO',
        description: '',
      },

      22: {
        name: 'LIQUIDATE_FRESHNESS_CHECK',
        description: '',
      },

      23: {
        name: 'LIQUIDATE_LIQUIDATOR_IS_BORROWER',
        description: '',
      },

      24: {
        name: 'LIQUIDATE_REPAY_BORROW_FRESH_FAILED',
        description: '',
      },

      25: {
        name: 'LIQUIDATE_SEIZE_BALANCE_INCREMENT_FAILED',
        description: '',
      },

      26: {
        name: 'LIQUIDATE_SEIZE_BALANCE_DECREMENT_FAILED',
        description: '',
      },

      27: {
        name: 'LIQUIDATE_SEIZE_COMPTROLLER_REJECTION',
        description: '',
      },

      28: {
        name: 'LIQUIDATE_SEIZE_LIQUIDATOR_IS_BORROWER',
        description: '',
      },

      29: {
        name: 'LIQUIDATE_SEIZE_TOO_MUCH',
        description: '',
      },

      30: {
        name: 'MINT_ACCRUE_INTEREST_FAILED',
        description: '',
      },

      31: {
        name: 'MINT_COMPTROLLER_REJECTION',
        description: '',
      },

      32: {
        name: 'MINT_EXCHANGE_CALCULATION_FAILED',
        description: '',
      },

      33: {
        name: 'MINT_EXCHANGE_RATE_READ_FAILED',
        description: '',
      },

      34: {
        name: 'MINT_FRESHNESS_CHECK',
        description: '',
      },

      35: {
        name: 'MINT_NEW_ACCOUNT_BALANCE_CALCULATION_FAILED',
        description: '',
      },

      36: {
        name: 'MINT_NEW_TOTAL_SUPPLY_CALCULATION_FAILED',
        description: '',
      },

      37: {
        name: 'MINT_TRANSFER_IN_FAILED',
        description: '',
      },

      38: {
        name: 'MINT_TRANSFER_IN_NOT_POSSIBLE',
        description: '',
      },

      39: {
        name: 'REDEEM_ACCRUE_INTEREST_FAILED',
        description: '',
      },

      40: {
        name: 'REDEEM_COMPTROLLER_REJECTION',
        description: '',
      },

      41: {
        name: 'REDEEM_EXCHANGE_TOKENS_CALCULATION_FAILED',
        description: '',
      },

      42: {
        name: 'REDEEM_EXCHANGE_AMOUNT_CALCULATION_FAILED',
        description: '',
      },

      43: {
        name: 'REDEEM_EXCHANGE_RATE_READ_FAILED',
        description: '',
      },

      44: {
        name: 'REDEEM_FRESHNESS_CHECK',
        description: '',
      },

      45: {
        name: 'REDEEM_NEW_ACCOUNT_BALANCE_CALCULATION_FAILED',
        description: '',
      },

      46: {
        name: 'REDEEM_NEW_TOTAL_SUPPLY_CALCULATION_FAILED',
        description: '',
      },

      47: {
        name: 'REDEEM_TRANSFER_OUT_NOT_POSSIBLE',
        description: '',
      },

      48: {
        name: 'REDUCE_RESERVES_ACCRUE_INTEREST_FAILED',
        description: '',
      },

      49: {
        name: 'REDUCE_RESERVES_ADMIN_CHECK',
        description: '',
      },

      50: {
        name: 'REDUCE_RESERVES_CASH_NOT_AVAILABLE',
        description: '',
      },

      51: {
        name: 'REDUCE_RESERVES_FRESH_CHECK',
        description: '',
      },

      52: {
        name: 'REDUCE_RESERVES_VALIDATION',
        description: '',
      },

      53: {
        name: 'REPAY_BEHALF_ACCRUE_INTEREST_FAILED',
        description: '',
      },

      54: {
        name: 'REPAY_BORROW_ACCRUE_INTEREST_FAILED',
        description: '',
      },

      55: {
        name: 'REPAY_BORROW_ACCUMULATED_BALANCE_CALCULATION_FAILED',
        description: '',
      },

      56: {
        name: 'REPAY_BORROW_COMPTROLLER_REJECTION',
        description: '',
      },

      57: {
        name: 'REPAY_BORROW_FRESHNESS_CHECK',
        description: '',
      },

      58: {
        name: 'REPAY_BORROW_NEW_ACCOUNT_BORROW_BALANCE_CALCULATION_FAILED',
        description: '',
      },

      59: {
        name: 'REPAY_BORROW_NEW_TOTAL_BALANCE_CALCULATION_FAILED',
        description: '',
      },

      60: {
        name: 'REPAY_BORROW_TRANSFER_IN_NOT_POSSIBLE',
        description: '',
      },

      61: {
        name: 'SET_COLLATERAL_FACTOR_OWNER_CHECK',
        description: '',
      },

      62: {
        name: 'SET_COLLATERAL_FACTOR_VALIDATION',
        description: '',
      },

      63: {
        name: 'SET_COMPTROLLER_OWNER_CHECK',
        description: '',
      },

      64: {
        name: 'SET_INTEREST_RATE_MODEL_ACCRUE_INTEREST_FAILED',
        description: '',
      },

      65: {
        name: 'SET_INTEREST_RATE_MODEL_FRESH_CHECK',
        description: '',
      },

      66: {
        name: 'SET_INTEREST_RATE_MODEL_OWNER_CHECK',
        description: '',
      },

      67: {
        name: 'SET_MAX_ASSETS_OWNER_CHECK',
        description: '',
      },

      68: {
        name: 'SET_ORACLE_MARKET_NOT_LISTED',
        description: '',
      },

      69: {
        name: 'SET_PENDING_ADMIN_OWNER_CHECK',
        description: '',
      },

      70: {
        name: 'SET_RESERVE_FACTOR_ACCRUE_INTEREST_FAILED',
        description: '',
      },

      71: {
        name: 'SET_RESERVE_FACTOR_ADMIN_CHECK',
        description: '',
      },

      72: {
        name: 'SET_RESERVE_FACTOR_FRESH_CHECK',
        description: '',
      },

      73: {
        name: 'SET_RESERVE_FACTOR_BOUNDS_CHECK',
        description: '',
      },

      74: {
        name: 'TRANSFER_COMPTROLLER_REJECTION',
        description: '',
      },

      75: {
        name: 'TRANSFER_NOT_ALLOWED',
        description: '',
      },

      76: {
        name: 'TRANSFER_NOT_ENOUGH',
        description: '',
      },

      77: {
        name: 'TRANSFER_TOO_MUCH',
        description: '',
      },
    },
  },
  comptroller: {
    errors: {
      0: {
        name: 'NO_ERROR',
        description: 'Not a failure.',
      },
      1: {
        name: 'UNAUTHORIZED',
        description: 'The sender is not authorized to perform this action.',
      },
      2: {
        name: 'COMPTROLLER_MISMATCH',
        description: 'Liquidation cannot be performed in markets with different comptrollers.',
      },
      3: {
        name: 'INSUFFICIENT_SHORTFALL',
        description: 'The account does not have sufficient shortfall to perform this action.',
      },
      4: {
        name: 'INSUFFICIENT_LIQUIDITY',
        description: 'The account does not have sufficient liquidity to perform this action.',
      },
      5: {
        name: 'INVALID_CLOSE_FACTOR',
        description: 'The close factor is not valid.',
      },
      6: {
        name: 'INVALID_COLLATERAL_FACTOR',
        description: 'The collateral factor is not valid.',
      },
      7: {
        name: 'INVALID_LIQUIDATION_INCENTIVE',
        description: 'The liquidation incentive is invalid.',
      },
      8: {
        name: 'MARKET_NOT_ENTERED',
        description: 'The market has not been entered by the account.',
      },
      9: {
        name: 'MARKET_NOT_LISTED',
        description: 'The market is not currently listed by the comptroller.',
      },
      10: {
        name: 'MARKET_ALREADY_LISTED',
        description: 'An admin tried to list the same market more than once.',
      },
      11: {
        name: 'MATH_ERROR',
        description: 'A math calculation error occurred.',
      },
      12: {
        name: 'NONZERO_BORROW_BALANCE',
        description: 'The action cannot be performed since the account carries a borrow balance.',
      },
      13: {
        name: 'PRICE_ERROR',
        description: 'The comptroller could not obtain a required price of an asset.',
      },
      14: {
        name: 'REJECTION',
        description: 'The comptroller rejects the action requested by the market.',
      },
      15: {
        name: 'SNAPSHOT_ERROR',
        description:
          'The comptroller could not get the account borrows and exchange rate from the market.',
      },
      16: {
        name: 'TOO_MANY_ASSETS',
        description: 'Attempted to enter more markets than are currently supported.',
      },
      17: {
        name: 'TOO_MUCH_REPAY',
        description: 'Attempted to repay more than is allowed by the protocol.',
      },
    },
    info: {
      0: {
        name: 'ACCEPT_ADMIN_PENDING_ADMIN_CHECK',
        description: '',
      },
      1: {
        name: 'ACCEPT_PENDING_IMPLEMENTATION_ADDRESS_CHECK',
        description: '',
      },
      2: {
        name: 'EXIT_MARKET_BALANCE_OWED',
        description: '',
      },
      3: {
        name: 'EXIT_MARKET_REJECTION',
        description: '',
      },
      4: {
        name: 'SET_CLOSE_FACTOR_OWNER_CHECK',
        description: '',
      },
      5: {
        name: 'SET_CLOSE_FACTOR_VALIDATION',
        description: '',
      },
      6: {
        name: 'SET_COLLATERAL_FACTOR_OWNER_CHECK',
        description: '',
      },
      7: {
        name: 'SET_COLLATERAL_FACTOR_NO_EXISTS',
        description: '',
      },
      8: {
        name: 'SET_COLLATERAL_FACTOR_VALIDATION',
        description: '',
      },
      9: {
        name: 'SET_COLLATERAL_FACTOR_WITHOUT_PRICE',
        description: '',
      },
      10: {
        name: 'SET_IMPLEMENTATION_OWNER_CHECK',
        description: '',
      },
      11: {
        name: 'SET_LIQUIDATION_INCENTIVE_OWNER_CHECK',
        description: '',
      },
      12: {
        name: 'SET_LIQUIDATION_INCENTIVE_VALIDATION',
        description: '',
      },
      13: {
        name: 'SET_MAX_ASSETS_OWNER_CHECK',
        description: '',
      },
      14: {
        name: 'SET_PENDING_ADMIN_OWNER_CHECK',
        description: '',
      },
      15: {
        name: 'SET_PENDING_IMPLEMENTATION_OWNER_CHECK',
        description: '',
      },
      16: {
        name: 'SET_PRICE_ORACLE_OWNER_CHECK',
        description: '',
      },
      17: {
        name: 'SUPPORT_MARKET_EXISTS',
        description: '',
      },
      18: {
        name: 'SUPPORT_MARKET_OWNER_CHECK',
        description: '',
      },
    },
  },
};
