<script>
import compoundRules from '@/mixins/compoundRules';
import Button from '@/components/Button';

export default {
  name: 'ModalCompoundSupplyTab',
  components: { Button },
  mixins: [compoundRules],
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    vault: {
      type: Object,
      default() {
        return {};
      },
    },
    allowanceWei: {
      type: [String, Object, Number],
      default: '0',
    },
    maxValue: {
      type: [String, Number],
    },
  },
  methods: {
    async approveAction() {
      this.$store.commit('Comp/setLoading', true);
      try {
        await this.$erc20.approveToken(this.vault.underlyingAddress, this.vault.address);
        this.$store.commit('Comp/modalHandler');
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.commit('Comp/setLoading', false);
      }
    },
    async depositAction() {
      // eslint-disable-next-line max-len
      const value = this.$contracts.toWei(this.value, this.vault.underlyingDecimals).toString();

      await this.$store
        .dispatch('Comp/suplly', {
          address: this.vault.address,
          value,
          symbol: this.vault.symbol,
        })
        .catch(() => {
          this.$store.commit('Comp/setLoading', false);
        });

      this.$emit('clearValue');
    },
  },
  computed: {
    needApprove() {
      return (
        this.$BN(this.allowanceWei).lt(
          this.$contracts.toWei(this.value, this.vault.underlyingDecimals).toString(),
        ) || this.$BN(this.allowanceWei).lte(0)
      );
    },
    isLoading() {
      return this.$store.getters['Comp/loading'];
    },
    hasError() {
      const value = this.$BN(this.value);
      const maxValue = this.$BN(this.maxValue);

      if (value.isGreaterThan(maxValue)) {
        return {
          disabled: true,
          text: 'Insufficient balance',
        };
      }
      return {
        disabled: false,
        text: 'Supply',
      };
    },
  },
};
</script>

<template>
  <div class="modal__btns">
    <Button v-if="needApprove" :disabled="isLoading" @click="approveAction">Enable</Button>
    <Button
      v-else
      :disabled="isLoading || $v.$invalid || hasError.disabled"
      @click="depositAction"
      >{{ hasError.text }}</Button
    >
  </div>
</template>

<style></style>
