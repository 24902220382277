<script>
import compoundRules from '@/mixins/compoundRules';
import Button from '@/components/Button';

export default {
  name: 'ModalCompoundWithdrawTab',
  components: { Button },
  mixins: [compoundRules],
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    vault: {
      type: Object,
      default() {
        return {};
      },
    },
    maxValue: {
      type: [String, Number],
    },
    withdrawType: String,
  },
  computed: {
    isLoading() {
      return this.$store.getters['Comp/loading'];
    },
    hasError() {
      const value = this.$BN(this.value);
      const maxValue = this.$BN(this.maxValue);

      if (value.isGreaterThan(maxValue)) {
        return {
          disabled: true,
          text: 'Insufficient balance',
        };
      }
      return {
        disabled: false,
        text: 'Withdraw',
      };
    },
  },
  methods: {
    async withdrawAction() {
      let value;
      if (this.withdrawType === 'redeem') {
        value = this.vault.cTokenBalance;
      } else {
        value = this.$contracts.toWei(this.value, this.vault.underlyingDecimals).toString();
      }

      await this.$store
        .dispatch('Comp/withdraw', {
          address: this.vault.address,
          symbol: this.vault.symbol,
          value,
          withdrawType: this.withdrawType,
          exchangeRate: this.vault.exchangeRate,
        })
        .catch(() => {
          this.$store.commit('Comp/setLoading', false);
        });
    },
  },
};
</script>

<template>
  <div>
    <div class="modal__btns">
      <Button
        v-if="withdrawType === 'redeem'"
        @click="withdrawAction"
        :disabled="isLoading || $v.$invalid"
        >Withdraw Full</Button
      >
      <Button
        v-else
        @click="withdrawAction"
        :disabled="isLoading || $v.$invalid || hasError.disabled"
        >{{ hasError.text }}</Button
      >
    </div>
  </div>
</template>

<style></style>
