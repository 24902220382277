<script>
import compoundRules from '@/mixins/compoundRules';
import Button from '@/components/Button';

export default {
  name: 'ModalCompoundBorrowTab',
  components: { Button },
  mixins: [compoundRules],
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    vault: {
      type: Object,
      default() {
        return {};
      },
    },
    maxValue: {
      type: [String, Number],
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters['Comp/loading'];
    },
    hasError() {
      const value = this.$BN(this.value);
      const maxValue = this.$BN(this.maxValue);

      if (value.isGreaterThan(maxValue)) {
        return {
          disabled: true,
          text: 'Insufficient collateral',
        };
      }
      return {
        disabled: false,
        text: 'Borrow',
      };
    },
  },
  methods: {
    async borrowAction() {
      // eslint-disable-next-line max-len
      const value = this.$contracts.toWei(this.value, this.vault.underlyingDecimals).toString();
      await this.$store
        .dispatch('Comp/borrow', {
          address: this.vault.address,
          value,
          symbol: this.vault.symbol,
        })
        .catch(() => {
          this.$store.commit('Comp/setLoading', false);
        });

      this.$emit('clearValue');
    },
  },
};
</script>

<template>
  <div class="modal__btns">
    <Button @click="borrowAction" :disabled="isLoading || $v.$invalid || hasError.disabled">{{
      hasError.text
    }}</Button>
  </div>
</template>

<style></style>
