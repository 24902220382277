import { required } from 'vuelidate/lib/validators';

const greaterThanZero = (value) => value > 0;
export default {
  validations() {
    return {
      value: {
        required,
        minValue: greaterThanZero,
      },
    };
  },
};
