<script>
import compoundRules from '@/mixins/compoundRules';
import Button from '@/components/Button';

export default {
  name: 'ModalCompoundRepayTab',
  components: { Button },
  mixins: [compoundRules],
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    vault: {
      type: Object,
      default() {
        return {};
      },
    },
    maxValue: {
      type: [String, Number],
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters['Comp/loading'];
    },
    hasError() {
      const value = this.$BN(this.value);
      const maxValue = this.$BN(this.maxValue);

      if (value.isGreaterThan(maxValue)) {
        return {
          disabled: true,
          text: 'Excessive Repayment',
        };
      }
      return {
        disabled: false,
        text: 'Repay',
      };
    },
  },
  methods: {
    async repayAction() {
      const value = this.$contracts.toWei(this.value, this.vault.underlyingDecimals);

      await this.$store
        .dispatch('Comp/repay', {
          address: this.vault.address,
          symbol: this.vault.symbol,
          value: value.toString(),
        })
        .catch(() => {
          this.$store.commit('Comp/setLoading', false);
        });
    },
    async repayFullAction() {
      const value = this.$contracts.toWei(this.value, this.vault.underlyingDecimals);

      await this.$store
        .dispatch('Comp/repayFull', {
          address: this.vault.address,
          symbol: this.vault.symbol,
          value: value.toString(),
        })
        .catch(() => {
          this.$store.commit('Comp/setLoading', false);
        });
    },
  },
};
</script>

<template>
  <div class="modal__btns">
    <Button @click="repayAction" :disabled="isLoading || $v.$invalid || hasError.disabled">{{
      hasError.text
    }}</Button>
    <Button
      @click="repayFullAction"
      :disabled="isLoading || hasError.disabled || maxValue <= 0"
      >Repay full</Button
    >
  </div>
</template>

<style></style>
