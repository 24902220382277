<script>
import { mapGetters } from 'vuex';
import closeIcon from '@/assets/img/close-icon.svg';
import ModalCompoundSupplyTab from '@/components/Modals/Compound/ModalCompoundSupplyTab';
import ModalCompoundWithdrawTab from '@/components/Modals/Compound/ModalCompoundWithdrawTab';
import MainInput from '@/components/Controls/MainInput';
import MainInputPercent from '@/components/Controls/MainInputPercent';

export default {
  name: 'ModalCompoundSupply',
  components: {
    MainInputPercent,
    MainInput,
    ModalCompoundSupplyTab,
    ModalCompoundWithdrawTab,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    vault: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      closeIcon,
      currentAction: 'ModalCompoundSupplyTab',
      value: 0,
      allowanceWei: 0,
    };
  },
  created() {
    this.$root.$on('showSupplyModal', this.showModalHandler);
  },
  beforeDestroy() {
    this.$root.$off('showSupplyModal', this.showModalHandler);
  },
  computed: {
    ...mapGetters({
      sortedVaults: 'Comp/sortedVaults',
    }),
    maxValue() {
      if (this.currentAction === 'ModalCompoundSupplyTab') {
        return this.vault.underlyingBalance.toString();
      }
      return this.vault.availableWithdraw.toString();
    },
    needApprove() {
      if (this.vault) {
        return (
          this.$BN(this.allowanceWei).lt(
            this.$contracts.toWei(this.value, this.vault.underlyingDecimals).toString(),
          ) || this.$BN(this.allowanceWei).lte(0)
        );
      }
      return false;
    },
    balanceLabel() {
      if (this.currentAction === 'ModalCompoundSupplyTab') {
        return 'Ready for supply';
      }
      return 'Ready for withdraw';
    },
    decimals() {
      if (
        this.vault.underlyingSymbol === 'USDT'
        && this.currentAction === 'ModalCompoundSupplyTab'
      ) {
        return this.vault.decimals;
      }
      if (
        this.vault.underlyingSymbol === 'BNB'
        && this.currentAction !== 'ModalCompoundSupplyTab'
      ) {
        return 8;
      }

      return this.vault.underlyingDecimals;
    },
    withdrawType() {
      if (Object.entries(this.sortedVaults.borrowed).length) {
        return 'redeemUnderlying';
      }
      return 'redeem';
    },
    percentValues() {
      if (
        this.withdrawType === 'redeemUnderlying'
        && this.currentAction !== 'ModalCompoundSupplyTab'
      ) {
        return [25, 50, 75];
      }
      return [25, 50, 75, 100];
    },
    disableInput() {
      if (this.withdrawType === 'redeem' && this.currentAction === 'ModalCompoundWithdrawTab') {
        return true;
      }
      return false;
    },
  },
  watch: {
    show(val) {
      this.value = 0;
      this.currentAction = 'ModalCompoundSupplyTab';
      if (val) {
        this.getAllowance();
      }
    },
    currentAction() {
      if (this.withdrawType !== 'redeem' && this.currentAction === 'ModalCompoundWithdrawTab') {
        this.value = 0;
      }
    },
  },
  methods: {
    async getAllowance() {
      if (this.vault.underlyingAddress !== '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee') {
        this.allowanceWei = await this.$erc20.getTokenAllowance(
          this.vault.underlyingAddress,
          this.vault.address,
        );
      } else {
        this.allowanceWei = this.$BN(2)
          .pow(256)
          .minus(1)
          .toString();
      }
    },
    setValueFromPercent(percent) {
      if (this.currentAction === 'ModalCompoundSupplyTab') {
        this.value = this.$BN(this.vault.underlyingBalance)
          .times(percent)
          .div(100)
          .toString(10);
      } else {
        this.value = this.$BN(this.vault.availableWithdraw)
          .times(percent)
          .div(100)
          .toString();
      }
    },
    setTab(tab) {
      this.currentAction = tab;

      if (this.withdrawType === 'redeem' && this.currentAction === 'ModalCompoundWithdrawTab') {
        this.value = this.vault.currentDeposit.toString();
      } else {
        this.value = 0;
      }
    },
    clearValue() {
      this.value = 0;
    },
  },
};
</script>

<template>
  <v-dialog @input="$emit('update:show')" :value="show">
    <div class="main-block modal modal--light">
      <div class="modal__close-button" @click="$emit('update:show')">
        <img class="modal__close-icon" :src="require(`@/assets/img/cross.svg`)" />
      </div>
      <div class="modal__icon-box">
        <img
          class="modal__icon"
          :src="require(`@/assets/img/spacelend/${vault.underlyingSymbol}.svg`)"
        />
      </div>
      <div class="modal__sub-ttl">
        Enable <span>{{ this.vault.underlyingSymbol }}</span> first to Supply/Borrow or Repay it to
        the SpaceSwap Protocol
      </div>
      <div v-if="!needApprove">
        <div class="modal-tabs">
          <div
            :class="[
              'modal-tabs__tab',
              { 'modal-tabs__tab--current': currentAction === 'ModalCompoundSupplyTab' }
            ]"
            @click="setTab('ModalCompoundSupplyTab')"
          >
            Supply
          </div>
          <div
            :class="[
              'modal-tabs__tab',
              { 'modal-tabs__tab--current': currentAction === 'ModalCompoundWithdrawTab' }
            ]"
            @click="setTab('ModalCompoundWithdrawTab')"
          >
            Withdraw
          </div>
        </div>
        <MainInput
          v-model="value"
          :maxValue="maxValue"
          :decimals="decimals"
          :disabled="disableInput"
          :inputLabel="balanceLabel"
        >
          <template
            v-slot:controls
            v-if="currentAction === 'ModalCompoundSupplyTab' || withdrawType === 'redeemUnderlying'"
          >
            <MainInputPercent
              class="mt-6"
              :values="percentValues"
              @setValue="setValueFromPercent"
            />
          </template>
        </MainInput>
      </div>
      <component
        :withdrawType="withdrawType"
        :is="currentAction"
        :vault="vault"
        :value="value"
        :maxValue="maxValue"
        :allowanceWei="allowanceWei"
        @clearValue="clearValue"
      ></component>
    </div>
  </v-dialog>
</template>

<style></style>
