<template>
  <div
    class="dashboard-info__col apy main-block"
    :class="{ active: active }"
    @mouseover="(active = true), (toFixed = 4)"
    @mouseleave="(active = false), (toFixed = 2)"
  >
    <span v-if="!active" class="dashboard-info__col--label apy__label">
      {{ $t("dashboard.net-apy") }}
    </span>
    <span class="apy__label" v-else>Interest earned and paid </span>
    <span class="dashboard-info__col--value apy__value" v-if="netAPY"
      >{{ netAPY.toFixed(toFixed) }} %</span
    >
    <img width="60px" src="@/assets/img/btn_menu.png" v-else />
  </div>
</template>

<script>
export default {
  props: {
    netAPY: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      active: false,
      toFixed: 2,
    };
  },
};
</script>

<style lang="scss" scoped>
.active {
  .apy__label {
    font-size: 1.3rem;
  }
    .apy__value {
    font-size: 2.4rem;
  }
}
// .apy {
//   display: flex;
//     justify-content: space-between;
//     margin-top: 4rem;
//     align-items: center;
//   width: 18rem;
//   height: 18rem;
//   border-radius: 50%;
//   align-items: center;
//   justify-content: center;

//   &__label {
//     font-size: 1.6rem;
//     font-weight: 500;
//     margin-bottom: 0.5rem;
//   }

//   &__value {
//     font-size: 3.2rem;
//     font-weight: 600;
//   }
// }
</style>
