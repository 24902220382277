<template>
  <div class="dashboard-table main-block">
    <span class="dashboard-table--title">
      {{ $t("dashboard.supply-market") }}
    </span>
    <div class="dashboard-table__header">
      <div class="dashboard-table__header--label">
        <span>{{ $t("dashboard.asset") }}</span>
      </div>
      <div class="dashboard-table__header--label">
        <span>{{ $t("dashboard.apy") }}</span>
      </div>
      <div class="dashboard-table__header--label">
        <span>In Supply</span>
      </div>
      <div class="dashboard-table__header--label">
        <span>{{ $t("dashboard.wallet") }}</span>
      </div>
    </div>
    <SpaceLendSupplyMarketItem
      v-for="(vault, key) in vaults"
      :key="key"
      :vault="vault"
      @showSupplyModal="handleShowModal"
    />
    <ModalCompoundSupply
      :show="showSupplyModal"
      :vault="selectedVault"
      @update:show="showSupplyModal = !showSupplyModal"
    />
  </div>
</template>

<script>
import SpaceLendSupplyMarketItem from '@/components/SpaceLend/SpaceLendSupply/SpaceLendSupplyMarketItem';
import ModalCompoundSupply from '@/components/Modals/Compound/ModalCompoundSupply';

export default {
  name: 'SpaceLendSupplyMarket',
  components: { SpaceLendSupplyMarketItem, ModalCompoundSupply },
  props: {
    vaults: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      showSupplyModal: false,
      selectedVault: null,
      modalHandler: undefined,
    };
  },
  created() {
    this.modalHandler = this.$store.subscribe((mutation) => {
      if (mutation.type === 'Comp/modalHandler') {
        this.showSupplyModal = false;
      }
    });

    this.$store.commit('Comp/modalHandler');
  },
  beforeDestroy() {
    this.modalHandler();
  },
  methods: {
    handleShowModal(vault) {
      this.showSupplyModal = true;
      this.selectedVault = vault;
    },
  },
};
</script>

<style></style>
