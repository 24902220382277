var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"value":_vm.show},on:{"input":function($event){return _vm.$emit('update:show')}}},[_c('div',{staticClass:"main-block modal modal--light"},[_c('div',{staticClass:"modal__close-button",on:{"click":function($event){return _vm.$emit('update:show')}}},[_c('img',{staticClass:"modal__close-icon",attrs:{"src":require(`@/assets/img/cross.svg`)}})]),_c('div',{staticClass:"modal__sub-ttl"},[_vm._v(" Enable "),_c('b',[_vm._v(_vm._s(this.vault.underlyingSymbol))]),_vm._v(" first to Supply/Borrow or Repay it to the SpaceSwap Protocol ")]),_c('div',{staticClass:"modal-tabs"},[_c('div',{class:[
          'modal-tabs__tab',
          { 'modal-tabs__tab--current': _vm.currentAction === 'ModalCompoundBorrowTab' }
        ],on:{"click":function($event){_vm.currentAction = 'ModalCompoundBorrowTab'}}},[_vm._v(" Borrow ")]),_c('div',{class:[
          'modal-tabs__tab',
          { 'modal-tabs__tab--current': _vm.currentAction === 'ModalCompoundRepayTab' }
        ],on:{"click":function($event){_vm.currentAction = 'ModalCompoundRepayTab'}}},[_vm._v(" Repay ")])]),_c('MainInput',{attrs:{"maxValue":_vm.maxValue,"decimals":_vm.decimals,"inputLabel":_vm.balanceLabel},scopedSlots:_vm._u([(_vm.currentAction === 'ModalCompoundBorrowTab')?{key:"controls",fn:function(){return [_c('MainInputPercent',{attrs:{"values":[25, 50, 75]},on:{"setValue":_vm.setValueFromPercent}})]},proxy:true}:{key:"controls",fn:function(){return [_c('MainInputPercent',{attrs:{"values":[25, 50, 75, 100]},on:{"setValue":_vm.setValueFromPercent}})]},proxy:true}],null,true),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c(_vm.currentAction,{tag:"component",attrs:{"vault":_vm.vault,"value":_vm.value,"maxValue":_vm.maxValue},on:{"clearValue":_vm.clearValue}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }