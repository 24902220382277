<script>
import { mapGetters } from 'vuex';
import SwitchToggle from '@/components/SwitchToggle';
import Button from '@/components/Button';

export default {
  name: 'SpaceLendSupplyItem',
  props: {
    vault: {
      type: Object,
      default() {
        return {
          symbol: '',
          address: '',
        };
      },
    },
  },
  components: {
    Button,
    SwitchToggle,
  },
  data() {
    return {
      loading: false,
      assetIn: this.vault.assetIn,
      showWarning: false,
      needToRepay: 0,
      warningText: '',
    };
  },
  computed: {
    ...mapGetters({
      sortedVaults: 'Comp/sortedVaults',
    }),
    showHint() {
      if (!this.vault.assetIn) {
        return true;
      }
      return false;
    },
  },
  methods: {
    exitMarket() {
      const inBorrow = Object.keys(this.sortedVaults.borrowed).includes(this.vault.address);

      const diff = this.vault.currentDeposit
        .times(this.vault.collateral)
        .minus(this.vault.accountLiquidity).abs();

      const canExit = diff.gte(0);

      if (inBorrow) {
        this.showWarning = true;
        this.warningText = `You have ${this.vault.underlyingSymbol} in borrow. If you want to exit, you need to fully repay your ${this.vault.underlyingSymbol}.`;
        this.assetIn = !this.assetIn;
      } else if (!canExit) {
        this.showWarning = true;
        this.warningText = `You need to repay at least $ ${diff.toFormat(4)}`;
        this.assetIn = !this.assetIn;
      } else {
        this.loading = true;
        this.assetIn = !this.assetIn;
        this.toggleMarketStatus();
      }
    },
    enterMarket() {
      this.loading = true;
      this.assetIn = !this.assetIn;
      this.$store
        .dispatch('Comp/enterMarket', [this.vault.address])
        .catch(() => {
          this.assetIn = !this.assetIn;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toggleMarketStatus() {
      if (this.assetIn) {
        this.exitMarket();
      } else {
        this.enterMarket();
      }
    },
  },
};
</script>

<template>
  <div class="dashboard-table__body" @click="$emit('showSupplyModal', vault)">
    <div class="dashboard-table__body--col">
      <img class="icon"
      :src="require(`@/assets/img/spacelend/${vault.underlyingSymbol}.svg`)" />
      <span>{{ vault.underlyingSymbol }}</span>
    </div>
    <div class="dashboard-table__body--col">
      <span>{{ vault.tokenSupplyRate.substring(0, 4) }} %</span>
    </div>
    <div class="dashboard-table__body--col">
      <v-tooltip v-if="showHint" bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" class="dashboard-table__body--hint" v-if="showHint"
            >?</span
          >
        </template>
        <span>
          You still have <b>{{ vault.underlyingSymbol }}</b> on your Supply Balance. Toggle on if
          you wan't to use as collateral.</span
        >
      </v-tooltip>
      <span>
        {{ vault.currentDeposit | amount(2) }}
        {{ vault.underlyingSymbol }}
      </span>
    </div>
    <div class="dashboard-table__body--col">
      <span>
        {{ vault.underlyingBalance | amount(2) }}
        {{ vault.underlyingSymbol }}
      </span>
    </div>
    <div class="dashboard-table__body--col">
      <SwitchToggle v-model="assetIn" :disabled="loading" @click.native.stop="toggleMarketStatus" />
    </div>
    <v-dialog v-model="showWarning">
      <div class="main-block modal">
        <div class="modal__close-button" @click="showWarning = false">
          <img class="modal__close-icon" :src="require(`@/assets/img/cross.svg`)" />
        </div>
        <div>
          <p class="display-1">
            {{ warningText }}
          </p>
        </div>
        <div class="mt-4">
          <Button @click="showWarning = false">Ok</Button>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<style></style>
