<template>
  <v-dialog v-model="modal.show">
    <div class="main-block modal modal--light">
      <div class="modal__close-button" @click="modalHandler">
        <img class="modal__close-icon" :src="require(`@/assets/img/cross.svg`)" />
      </div>
      <div>
        <div v-if="modal.hash">
          <div class="modal__icon-box--tx">
            <img class="modal__icon" :src="require(`@/assets/img/binance/spin-animation.svg`)" />
          </div>
          <div class="modal__ttl--tx">Transaction Pending</div>
          <div class="modal__sub-ttl--tx">Waiting! Your transaction is being processed</div>
          <div class="mt-4">
            <Button :href="$chain.getExplorerLink(modal.hash)">VIEW ON BSCSCAN</Button>
          </div>
        </div>
        <div v-else-if="modal.receipt.events.Failure">
          <div class="modal__icon-box--tx">
            <img class="modal__icon" :src="require(`@/assets/img/error.svg`)" />
          </div>
          <div class="modal__ttl--tx">Transaction Failed</div>
          <div class="modal__sub-ttl--tx">
            Error: {{ modalErrors.error }} —
            {{ txError.name }}
            <p>
              {{ txError.description }}
            </p>
          </div>
          <div class="modal__sub-ttl--tx">
            Info: {{ modalErrors.info }} — {{ txDescription.name }}
            <p>
              {{ txDescription.description }}
            </p>
          </div>
          <div class="mt-4">
            <Button @click="modalHandler">Ok</Button>
          </div>
        </div>
        <div v-else>
          <div class="modal__icon-box--tx">
            <img class="modal__icon" :src="require(`@/assets/img/success.svg`)" />
          </div>
          <div class="modal__ttl--tx">Transaction Success</div>
          <div class="mt-4">
            <Button @click="modalHandler">Ok</Button>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import Button from '@/components/Button';
import componundErrors from '@/constants/compoundErrors';

export default {
  name: 'TransactionDialog',
  components: {
    Button,
  },
  data() {
    return {
      componundErrors,
    };
  },
  computed: {
    modal() {
      return this.$store.getters['Comp/txModal'];
    },
    modalErrors() {
      if (this.modal.receipt.events.Failure.returnValues) {
        return this.modal.receipt.events.Failure.returnValues;
      }

      return null;
    },
    txError() {
      if (this.modalErrors.error === '3') {
        return this.componundErrors.comptroller.errors[3].error;
      }

      return (
        this.componundErrors.cToken.errors[this.modalErrors.error]
        || 0
      );
    },
    txDescription() {
      if (this.modalErrors.error === '3') {
        return this.componundErrors.comptroller.info[
          this.modalErrors.info
        ].info;
      }

      return (
        this.componundErrors.cToken.info[this.modalErrors.error] || 0
      );
    },
  },
  methods: {
    modalHandler() {
      this.$store.commit('Comp/setTxModal', {
        show: false,
        hash: null,
        receipt: {
          events: {
            Failure: null,
          },
        },
      });
    },
  },
};
</script>

<style></style>
