<template>
  <label class="switch" :class="{'switch--disabled': disabled}">
    <input
      type="checkbox"
      :checked="value"
      :value="value"
      :disabled="disabled"
      @change="$emit('input', $event.target.checked)"
    />
    <span class="slider round"></span>
  </label>
</template>

<script>
export default {
  name: 'SwitchToggle',
  props: {
    value: Boolean,
    disabled: Boolean,
  },
};
</script>

<style lang="scss">
.switch {
  position: relative;
  display: inline-block;
  width: 65px;
  height: 34px;

  &--disabled {
    pointer-events: none;
  }
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, #1a1a20 0%, #181822 100%);
  box-shadow: 5px 5px 15px #26262f, -2px -2px 20px #23232d,
    inset -2px -2px 5px rgba(22, 22, 26, 0.48), inset 2px 2px 20px rgba(23, 24, 33, 0.2),
    inset -2px -1px 5px #171724, inset -4px -4px 20px rgba(0, 0, 0, 0.06);
  transition: 0.2s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  left: 2px;
  bottom: 2px;
  background: linear-gradient(180deg, #333344 0%, #2b2b3d 100%);
  box-shadow: 6px 6px 20px rgba(16, 16, 21, 0.2), 3px -3px 6px rgba(32, 32, 39, 0.2),
    -10px -10px 20px rgba(44, 44, 63, 0.1), 16px 16px 20px rgba(30, 30, 30, 0.15),
    inset 1px 1px 4px rgba(63, 62, 75, 0.2), inset -2px -2px 20px rgba(40, 40, 47, 0.2);
  transition: 0.2s;
}

input:checked + .slider {
  background: #2f2f2f;
  box-shadow: 5px 5px 15px #26262f, -2px -2px 20px #23232d,
    inset -2px -2px 5px rgba(22, 22, 26, 0.48), inset 2px 2px 20px rgba(23, 24, 33, 0.2),
    inset -2px -1px 5px #171724, inset -4px -4px 20px rgba(0, 0, 0, 0.06);
}

input:checked + .slider:before {
  background: linear-gradient(180deg, #f4d353 0%, #ffc117 100%);
  box-shadow: inset 1px 1px 2px rgba(255, 255, 255, 0.35),
    inset -1px -1px 2px rgba(221, 102, 102, 0.19);
}

input:checked + .slider:before {
  transform: translateX(30px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
