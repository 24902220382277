<script>
import closeIcon from '@/assets/img/close-icon.svg';
import MainInput from '@/components/Controls/MainInput';
import MainInputPercent from '@/components/Controls/MainInputPercent';
import ModalCompoundRepayTab from '@/components/Modals/Compound/ModalCompoundRepayTab';
import ModalCompoundBorrowTab from '@/components/Modals/Compound/ModalCompoundBorrowTab';

export default {
  name: 'ModalCompoundBorrow',
  components: {
    MainInputPercent,
    MainInput,
    ModalCompoundRepayTab,
    ModalCompoundBorrowTab,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    vault: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      closeIcon,
      currentAction: 'ModalCompoundBorrowTab',
      value: 0,
    };
  },
  watch: {
    show() {
      this.value = 0;
    },
    currentAction() {
      this.value = 0;
    },
  },
  computed: {
    maxValue() {
      if (this.currentAction === 'ModalCompoundBorrowTab') {
        return this.vault.availableBorrow.toString();
      }
      return this.vault.avaibleRepay.toString();
    },
    balanceLabel() {
      if (this.currentAction === 'ModalCompoundBorrowTab') {
        return 'Ready for borrow';
      }
      return 'Ready for repay';
    },
    showPercentage() {
      if (this.currentAction === 'ModalCompoundBorrowTab') {
        return false;
      }

      return true;
    },
    decimals() {
      if (this.vault.underlyingSymbol === 'USDT') {
        return this.vault.decimals;
      }

      return this.vault.underlyingDecimals;
    },
  },
  created() {
    this.$root.$on('showBorrowModal', this.showModalHandler);
  },
  beforeDestroy() {
    this.$root.$off('showBorrowModal', this.showModalHandler);
  },
  methods: {
    showModalHandler(vault) {
      this.showModal = true;
      this.vault = vault;
    },
    setValueFromPercent(percent) {
      if (this.currentAction === 'ModalCompoundBorrowTab') {
        this.value = this.$BN(this.vault.availableBorrow)
          .times(percent)
          .div(100)
          .toString();
      } else {
        this.value = this.$BN(this.vault.avaibleRepay)
          .times(percent)
          .div(100)
          .toString();
      }
    },
    clearValue() {
      this.value = 0;
    },
  },
};
</script>

<template>
  <v-dialog @input="$emit('update:show')" :value="show">
    <div class="main-block modal modal--light">
      <div class="modal__close-button" @click="$emit('update:show')">
        <img class="modal__close-icon" :src="require(`@/assets/img/cross.svg`)" />
      </div>
      <div class="modal__sub-ttl">
        Enable <b>{{ this.vault.underlyingSymbol }}</b> first to Supply/Borrow or Repay it to the
        SpaceSwap Protocol
      </div>
      <div class="modal-tabs">
        <div
          :class="[
            'modal-tabs__tab',
            { 'modal-tabs__tab--current': currentAction === 'ModalCompoundBorrowTab' }
          ]"
          @click="currentAction = 'ModalCompoundBorrowTab'"
        >
          Borrow
        </div>
        <div
          :class="[
            'modal-tabs__tab',
            { 'modal-tabs__tab--current': currentAction === 'ModalCompoundRepayTab' }
          ]"
          @click="currentAction = 'ModalCompoundRepayTab'"
        >
          Repay
        </div>
      </div>
      <MainInput
        v-model="value"
        :maxValue="maxValue"
        :decimals="decimals"
        :inputLabel="balanceLabel"
      >
        <template v-slot:controls v-if="currentAction === 'ModalCompoundBorrowTab'">
          <MainInputPercent :values="[25, 50, 75]" @setValue="setValueFromPercent" />
        </template>
        <template v-slot:controls v-else>
          <MainInputPercent :values="[25, 50, 75, 100]" @setValue="setValueFromPercent" />
        </template>
      </MainInput>
      <component
        :is="currentAction"
        :vault="vault"
        :value="value"
        :maxValue="maxValue"
        @clearValue="clearValue"
      ></component>
    </div>
  </v-dialog>
</template>

<style></style>
