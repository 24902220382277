<script>
export default {
  name: 'SpaceLendSupplyMarketItem',
  props: {
    vault: {
      type: Object,
      default() {
        return {
          symbol: '',
          address: '',
        };
      },
    },
  },
  data() {
    return {
      loading: false,
      assetIn: this.vault.assetIn,
    };
  },
  computed: {
    showHint() {
      if (this.vault.currentDeposit.isGreaterThan(0)) {
        return true;
      }
      return false;
    },
  },
  methods: {
    toggleMarketStatus() {
      this.loading = true;
      this.assetIn = !this.assetIn;
      this.$store
        .dispatch('Comp/enterMarket', [this.vault.address])
        .catch(() => {
          this.assetIn = !this.assetIn;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<template>
  <div class="dashboard-table__body" @click="$emit('showSupplyModal', vault)">
    <div class="dashboard-table__body--col">
      <img class="icon" :src="require(`@/assets/img/spacelend/${vault.underlyingSymbol}.svg`)" />
      <span>{{ vault.underlyingSymbol }}</span>
    </div>
    <div class="dashboard-table__body--col">
      <span>{{ vault.tokenSupplyRate.substring(0, 4) }} %</span>
    </div>
    <div class="dashboard-table__body--col">
      {{ vault.currentDeposit | amount(2) }} {{ vault.underlyingSymbol }}
      <v-tooltip v-if="showHint" bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" class="dashboard-table__body--hint" v-if="showHint"
            >?</span
          >
        </template>
        <span>
          You still have <b>{{ vault.underlyingSymbol }}</b> on your Supply Balance. Toggle on if
          you wan't to use as collateral.</span
        >
      </v-tooltip>
    </div>
    <div class="dashboard-table__body--col">
      {{ vault.underlyingBalance | amount(2) }}{{ vault.underlyingSymbol }}
    </div>
    <!-- <div class="dashboard-table__body--col">
      <SwitchToggle v-model="assetIn" :disabled="loading" @click.native.stop="toggleMarketStatus" />
    </div> -->
  </div>
</template>

<style></style>
