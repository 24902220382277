<template>
  <li class="borrow-list__item">
    <v-avatar color="indigo" size="36">
      <img class="icon" :src="require(`@/assets/img/spacelend/${item.underlyingSymbol}.svg`)" />
    </v-avatar>
    <BorrowInput
      @keyup="calcToken"
      v-model="cTokenAmount"
      :decimals="decimals"
      :label="item.underlyingSymbol"
    />
    <BorrowInput
      @keyup="calcUSD"
      v-model="USDAmount"
      :maxValue="maxValue"
      :decimals="decimals"
      label="USDT"
      inputLabel="Max"
    />
  </li>
</template>

<script>
// import MainInput from '@/components/Controls/MainInput';
import BorrowInput from '@/components/BorrowInput';

export default {
  name: 'BorrowItem',
  components: {
    // MainInput,
    BorrowInput,
  },
  props: {
    vault: Object,
    maxValue: String,
  },
  data() {
    return {
      item: {
        ...this.vault,
      },
      cTokenAmount: 0,
      USDAmount: 0,
    };
  },
  computed: {
    decimals() {
      if (this.vault.underlyingSymbol === 'USDT') {
        return this.vault.decimals;
      }

      return this.vault.underlyingDecimals;
    },
  },
  methods: {
    calcToken() {
      const cToken = this.$BN(this.cTokenAmount);
      this.USDAmount = cToken.times(this.item.tokenPrice).toString();

      this.calculate();
    },
    calcUSD() {
      const usd = this.$BN(this.USDAmount);
      this.cTokenAmount = usd.div(this.item.tokenPrice).toString();

      this.calculate();
    },
    calculate() {
      if (this.cTokenAmount || this.USDAmount) {
        this.$emit('calculate', {
          cTokenAmount: this.cTokenAmount,
          USDAmount: this.USDAmount,
          address: this.vault.address,
          symbol: this.vault.symbol,
        });
      }
    },
  },
};
</script>

<style></style>
