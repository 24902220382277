<script>
import DashboardBorrowItem from '@/components/SpaceLend/SpaceLendBorrow/SpaceLendBorrowItem';
import ModalCompoundBorrow from '@/components/Modals/Compound/ModalCompoundBorrow';

export default {
  name: 'SpaceLendBorrowMarket',
  components: { ModalCompoundBorrow, DashboardBorrowItem },
  props: {
    vaults: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      showBorrowModal: false,
      selectedVault: null,
    };
  },
  methods: {
    handleShowModal(vault) {
      this.showBorrowModal = true;
      this.selectedVault = vault;
    },
  },
};
</script>

<template>
  <div class="dashboard-table main-block">
    <span class="dashboard-table--title">{{ $t('dashboard.borrow-market' )}}</span>
    <div class="dashboard-table__header">
      <div class="dashboard-table__header--label">
        <span>{{$t('dashboard.asset')}}</span>
      </div>
      <div class="dashboard-table__header--label">
        <span>{{$t('dashboard.apy')}}</span>
      </div>
      <div class="dashboard-table__header--label">
        <span>Borrow Balance</span>
      </div>
      <div class="dashboard-table__header--label">
        <span>{{$t('dashboard.liquidity')}}</span>
      </div>
    </div>
    <DashboardBorrowItem
      v-for="(vault, key) in vaults"
      :key="key"
      :vault="vault"
      @showBorrowModal="handleShowModal"
    />
    <ModalCompoundBorrow
      :show="showBorrowModal"
      :vault="selectedVault"
      @update:show="showBorrowModal = !showBorrowModal"
    />
  </div>
</template>

<style>

</style>
