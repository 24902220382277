<script>
export default {
  name: 'SpaceLendBorrowItem',
  props: {
    vault: {
      type: Object,
      default() {
        return {
          symbol: '',
          address: '',
        };
      },
    },
  },
  computed: {
    vaultLiquidity() {
      if (this.vault.underlyingSymbol === 'USDT') {
        return this.vault.currentBorrowBalance.multipliedBy(
          this.$contracts.fromWei(this.vault.cTokenPriceWei, 30),
        );
      }

      return this.vault.currentBorrowBalance.multipliedBy(
        this.$contracts.fromWei(this.vault.cTokenPriceWei, this.vault.underlyingDecimals),
      );
    },
    notZero() {
      if (
        this.vault.currentBorrowBalance.isLessThan(0.01)
        && this.vault.currentBorrowBalance.isGreaterThan(0)
      ) {
        return true;
      }

      return false;
    },
  },
};
</script>

<template>
  <div class="dashboard-table__body" @click="$emit('showBorrowModal', vault)">
    <div class="dashboard-table__body--col">
      <img class="icon" :src="require(`@/assets/img/spacelend/${vault.underlyingSymbol}.svg`)" />
      <span>{{ vault.underlyingSymbol }}</span>
    </div>
    <div class="dashboard-table__body--col">
      <span>{{ vault.tokenBorrowRate.substring(0, 4) }} %</span>
    </div>
    <div class="dashboard-table__body--col">
      <v-tooltip v-if="notZero" bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" class="dashboard-table__body--hint" v-if="notZero">~</span>
        </template>
        <span> {{ vault.currentBorrowBalance }}</span>
      </v-tooltip>
      <span>
        {{ vault.currentBorrowBalance | amount(2) }}
        {{ vault.underlyingSymbol }}
      </span>
    </div>
    <div class="dashboard-table__body--col">
      <span>
        $
        {{ vaultLiquidity | amount(2) }}
      </span>
    </div>
  </div>
</template>

<style></style>
