var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"value":_vm.show},on:{"input":function($event){return _vm.$emit('update:show')}}},[_c('div',{staticClass:"main-block modal modal--light"},[_c('div',{staticClass:"modal__close-button",on:{"click":function($event){return _vm.$emit('update:show')}}},[_c('img',{staticClass:"modal__close-icon",attrs:{"src":require(`@/assets/img/cross.svg`)}})]),_c('div',{staticClass:"modal__icon-box"},[_c('img',{staticClass:"modal__icon",attrs:{"src":require(`@/assets/img/spacelend/${_vm.vault.underlyingSymbol}.svg`)}})]),_c('div',{staticClass:"modal__sub-ttl"},[_vm._v(" Enable "),_c('span',[_vm._v(_vm._s(this.vault.underlyingSymbol))]),_vm._v(" first to Supply/Borrow or Repay it to the SpaceSwap Protocol ")]),(!_vm.needApprove)?_c('div',[_c('div',{staticClass:"modal-tabs"},[_c('div',{class:[
            'modal-tabs__tab',
            { 'modal-tabs__tab--current': _vm.currentAction === 'ModalCompoundSupplyTab' }
          ],on:{"click":function($event){return _vm.setTab('ModalCompoundSupplyTab')}}},[_vm._v(" Supply ")]),_c('div',{class:[
            'modal-tabs__tab',
            { 'modal-tabs__tab--current': _vm.currentAction === 'ModalCompoundWithdrawTab' }
          ],on:{"click":function($event){return _vm.setTab('ModalCompoundWithdrawTab')}}},[_vm._v(" Withdraw ")])]),_c('MainInput',{attrs:{"maxValue":_vm.maxValue,"decimals":_vm.decimals,"disabled":_vm.disableInput,"inputLabel":_vm.balanceLabel},scopedSlots:_vm._u([(_vm.currentAction === 'ModalCompoundSupplyTab' || _vm.withdrawType === 'redeemUnderlying')?{key:"controls",fn:function(){return [_c('MainInputPercent',{staticClass:"mt-6",attrs:{"values":_vm.percentValues},on:{"setValue":_vm.setValueFromPercent}})]},proxy:true}:null],null,true),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1):_vm._e(),_c(_vm.currentAction,{tag:"component",attrs:{"withdrawType":_vm.withdrawType,"vault":_vm.vault,"value":_vm.value,"maxValue":_vm.maxValue,"allowanceWei":_vm.allowanceWei},on:{"clearValue":_vm.clearValue}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }