<template>
  <div class="borrow-limit">
    <div class="borrow-limit__text">
      Borrow Limit
      <div class="borrow-limit__tooltip">
      Liquidation only applies if you have borrowed assets.
    </div>
    </div>
    <div class="limit-container">
      <div class="limit-bar" :style="{ width: `${position}%` }" id="limitBar"></div>
    </div>
    <span class="borrow-limit__procent">{{ borrowLimit }}%</span>

  </div>
</template>

<script>
export default {
  name: 'BorrowLimit',
  props: {
    borrowLimit: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    position() {
      return Number(this.borrowLimit) > 100 ? 100 : this.borrowLimit;
    },
  },
};
</script>

<style lang="scss">
.borrow-limit {
  display: flex;
  align-items: center;

  .limit-container {
    background-color: #68748b;
    position: relative;
    height: 3px;
    width: 100%;
    max-width: 400px;
  }

  .limit-bar {
    background-color: $b-binance-color;
    height: 3px;
    width: 0%;
  }

  &__tooltip {
    background: linear-gradient(180deg, #31313c 0%, #27272f 100%);
    box-shadow: 4px 4px 8px rgba(22, 22, 27, 0.6), -2px -2px 4px rgba(69, 69, 75, 0.3),
      inset 0px 0px 3px rgba(33, 33, 39, 0.2), inset 0px 0px 4px rgba(112, 112, 120, 0.2),
      inset -1px -1px 6px rgba(43, 43, 48, 0.6), inset 1px 1px 2px rgba(154, 154, 181, 0.2);
    border-radius: 1rem;
    padding: 1rem;
    display: none;
    font-weight: 500;
    font-size: 10px;
    max-width: 100%;
    position: absolute;
    top: 30px;
    left: 0;
  }

  &__text {
    font-size: 2rem;
    font-weight: 600;
    display: inline-block;
    margin-right: 1rem;
    position: relative;
    color: $b-secondatry-font-color;

    span {
      color: #fff;
      cursor: pointer;
    }

    &:hover {
    .borrow-limit__tooltip {
      display: block;
    }
  }
  }

  &__procent {
    font-size: 2rem;
    font-weight: 600;
    color: $b-secondatry-font-color;
    margin-left: 1rem;
  }
}
</style>
