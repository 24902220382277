<template>
  <v-dialog v-model="dialog" width="1000">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="yellow lighten-2" v-bind="attrs" v-on="on">
        <h3>
          Manage Borrow
        </h3>
      </v-btn>
    </template>

    <div class="main-block modal modal--light">
      <div class="modal__close-button" @click="dialog = false">
        <img class="modal__close-icon" :src="require(`@/assets/img/cross.svg`)" />
      </div>
      <div>
        <h1>You can borrow up to — $ {{ avaibleLiquidity.toFixed(2) }}</h1>
        <ul class="borrow-list">
          <BorrowItem
            v-for="(vault, i) in vaults"
            :vault="vault"
            :key="i"
            @calculate="calculate"
            :maxValue="avaibleLiquidity.toString()"
          />
        </ul>
      </div>
      <div class="mt-6">
        <Button @click="borrowAssets">Borrow</Button>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from '@/components/Button';
import BorrowItem from './BorrowItem';

export default {
  name: 'ModalBorrowManage',
  components: {
    BorrowItem,
    Button,
  },
  data() {
    return {
      dialog: false,
      accountLiquidity: this.$store.getters['Comp/accountLiquidity'],
      forBorrow: [],
      avaibleLiquidity: this.$store.getters['Comp/accountLiquidity'],
      hit: 0,
    };
  },
  watch: {
    hit: {
      deep: true,
      handler() {
        const diff = this.$BN(
          this.forBorrow.reduce((acc, item) => acc + Number(item.USDAmount), 0),
        );

        this.avaibleLiquidity = this.$BN(this.accountLiquidity).minus(diff);
      },
    },
  },
  computed: {
    ...mapGetters({
      vaults: 'Comp/vaults',
    }),
    items() {
      // eslint-disable-next-line no-unused-vars
      return Object.entries(this.vaults).map(([_, value]) => value);
    },
  },
  methods: {
    calculate(borrow) {
      this.hit += 1;
      const hasAddress = !!this.forBorrow.find((item) => item.address === borrow.address);

      if (!hasAddress) {
        this.forBorrow.push(borrow);
      }

      const index = this.forBorrow.findIndex((item) => item.address === borrow.address);

      if (Number(borrow.cTokenAmount) === 0) {
        this.forBorrow.splice(index, 1);
      }

      this.forBorrow[index] = borrow;
    },
    borrowAssets() {
      this.forBorrow.forEach((item) => {
        const params = {
          address: item.address,
          symbol: item.symbol,
          value: this.$BN(item.cTokenAmount).times(1e18),
        };

        if (this.$BN(item.cTokenAmount).gt(0)) {
          this.$store.dispatch('Comp/borrow', params);
        }
      });
    },
  },
};
</script>

<style lang="scss">
.borrow-list {
  &__item {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }
}
</style>
