<script>
import { mapState, mapGetters } from 'vuex';
import SpaceLendSupply from '@/components/SpaceLend/SpaceLendSupply/SpaceLendSupply';
import SpaceLendSupplyMarket from '@/components/SpaceLend/SpaceLendSupply/SpaceLendSupplyMarket';
import SpaceLendBorrow from '@/components/SpaceLend/SpaceLendBorrow/SpaceLendBorrow';
import SpaceLendBorrowMarket from '@/components/SpaceLend/SpaceLendBorrow/SpaceLendBorrowMarket';
import Button from '@/components/Button';
import BorrowLimitSlider from '@/components/SpaceLend/BorrowLimitSlider';
import TransactionDialog from '@/components/Modals/Compound/TransactionDialog';
import NetAPY from '@/components/SpaceLend/NetAPY';
import ModalBorrowManage from '@/components/Modals/Compound/ModalBorrowManage';

export default {
  name: 'Spacelend',
  components: {
    SpaceLendBorrowMarket,
    SpaceLendBorrow,
    SpaceLendSupplyMarket,
    SpaceLendSupply,
    Button,
    BorrowLimitSlider,
    TransactionDialog,
    NetAPY,
    ModalBorrowManage,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      chainId: (state) => state.User.chainId,
      vaults: (state) => state.Comp.vaults,
    }),
    ...mapGetters({
      netAPY: 'Comp/netAPY',
      sortedVaults: 'Comp/sortedVaults',
      totalSupply: 'Comp/totalSupply',
      totalBorrowed: 'Comp/totalBorrowed',
      accountLiquidity: 'Comp/accountLiquidity',
    }),
    borrowLimit() {
      return this.totalBorrowed
        .dividedBy(this.accountLiquidity.plus(this.totalBorrowed))
        .times(100)
        .toFormat(2);
    },
    isAllowedChain() {
      if (this.chainId === 97) {
        return true;
      }
      return false;
    },
  },
  watch: {
    chainId(val) {
      if (val) {
        this.$store.dispatch('Comp/init');
      }
    },
  },
  created() {
    if (this.chainId) {
      this.$store.dispatch('Comp/init');
    }
    this.$root.$on('newBlock', this.newBlockHandler);
  },
  methods: {
    newBlockHandler() {
      this.$store.dispatch('Comp/init');
    },
  },
};
</script>

<template>
  <div class="dashboard main-wrap">
    <div v-if="!isAllowedChain">
      <v-row justify="center">
        <h1>Spacelend currently is avaible only in BSC Testnet.</h1>
      </v-row>
    </div>
    <div v-else>
      <div class="dashboard-info">
        <div class="dashboard-info__col">
          <span class="dashboard-info__col--label">
            {{ $t("dashboard.supply-balance") }}
          </span>
          <span class="dashboard-info__col--value">$ {{ totalSupply | amount(2) }}</span>
        </div>
        <NetAPY :netAPY="netAPY" />
        <div class="dashboard-info__col">
          <span class="dashboard-info__col--label">
            {{ $t("dashboard.borrow-balance") }}
          </span>
          <span class="dashboard-info__col--value">$ {{ totalBorrowed | amount(2) }}</span>
          <span class="dashboard-info__col--label mt-3">
            Available to borrow
            <b> $ {{ accountLiquidity | amount(2) }} </b>
          </span>
          <ModalBorrowManage />
        </div>
      </div>
      <v-row justify="center" class="mt-2">
        <v-col cols="6">
          <BorrowLimitSlider :borrowLimit="borrowLimit" />
        </v-col>
      </v-row>
      <v-row class="dashboard-container" v-if="Object.keys(vaults).length">
        <v-col>
          <SpaceLendSupply
            v-if="Object.keys(sortedVaults.assetIn).length"
            :vaults="sortedVaults.assetIn"
          />
          <SpaceLendSupplyMarket
            v-if="Object.keys(sortedVaults.assetOut).length"
            :vaults="sortedVaults.assetOut"
          />
        </v-col>
        <v-col>
          <SpaceLendBorrow
            v-if="Object.keys(sortedVaults.borrowed).length"
            :vaults="sortedVaults.borrowed"
          />
          <SpaceLendBorrowMarket
            v-if="Object.keys(sortedVaults.notBorrowed).length"
            :vaults="sortedVaults.notBorrowed"
          />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="10" class="mb-10">
          <div class="notice pa-10">
            <p>
              The product is in beta, use it at your risk. <br />
              We are not responsible for any loss. How does it work?
            </p>
            <div class="mt-10">
              <Button class="btn--round"> Read this guide.</Button>
            </div>
          </div>
        </v-col>
      </v-row>
      <TransactionDialog />
    </div>
  </div>
</template>

<style lang="scss">
.dashboard {
  &-info {
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;
    align-items: center;
    &__col {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 33.33%;
      height: 6rem;

      &.apy {
        width: 18rem;
        height: 18rem;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
      }

      &--label {
        font-size: 1.6rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
      }

      &--value {
        font-size: 3.2rem;
        font-weight: 600;
      }
    }
  }
  &-container {
    margin: 6rem 0;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: $breakpoint-sm) {
      flex-direction: column;
    }
  }

  &-table {
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;

    @media screen and (max-width: $breakpoint-sm) {
      width: 100%;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    padding: 1.8rem 2.4rem;

    &--title {
      font-weight: 600;
      font-size: 2.9rem;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      margin-top: 3.2rem;
      margin-bottom: 2.4rem;
      padding: 1.6rem 1rem;
      &--label {
        font-size: 1.8rem;
        font-weight: 600;
        display: flex;
        justify-content: flex-end;
        flex: 1;
        padding: 0 1rem;

        &:first-child {
          // flex: 1;
          justify-content: flex-start;
        }

        &:last-child {
          // flex: 1;
          justify-content: flex-end;
        }
      }
    }

    &__body {
      display: flex;
      flex: 1;
      justify-content: space-between;
      border-bottom: 1px solid #333344;
      line-height: 40px;
      align-items: center;
      position: relative;
      padding: 1.6rem 1rem;

      &:hover {
        background-color: #24242d;
        border-color: transparent;
        border-radius: 1rem;

        &::before {
          content: "";
          position: absolute;
          top: 30%;
          left: -3%;
          width: 5px;
          height: 28px;
          background: linear-gradient(180deg, #fce156 0%, #e2c320 100%);
          border-radius: 10px;
        }
      }

      &:last-child {
        border-bottom: none;
      }

      &--col {
        display: flex;
        flex: 1;
        text-align: right;
        align-items: center;
        font-size: 1.6rem;
        font-weight: 500;
        justify-content: flex-end;
        cursor: pointer;
        max-width: 100%;
        min-width: 100px;
        padding: 0 1rem;
        position: relative;

        &:first-child {
          // flex: 1;
          padding-left: 0;
          justify-content: flex-start;
        }

        &:last-child {
          // flex: 1;
          padding-right: 0;
          justify-content: flex-end;
        }

        .icon {
          width: 3.4rem;
          height: 3.4rem;
          margin-right: 1rem;
          border-radius: 50%;
          background: #edf1f2;
        }
      }

      &--hint {
        position: absolute;
        top: 0;
        left: 0;
        right: -15px;
        color: $b-binance-color;
        padding: 0 1rem;
      }
    }
  }

  .notice {
    text-align: center;
    padding: 1rem 2rem;
    box-shadow: $main-item-shadow;
    font-size: 2rem;
  }
}
</style>
